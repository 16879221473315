<template>
  <div class="h-promotion-progress-bar" :class="{ [`promotion-status--${status}`]: true }">
    <div class="h-label-1 consumer-gift-card-promotion-title">{{ $t('components.h_promotion_progress_bar.title', { amount: rewardPercent }) }}</div>
    <div class="d-flex justify-space-between">
      <!-- Left section -->
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status === 'coming_soon'" class="h-progress-bar-text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.progress_coming_soon' : 'components.h_promotion_progress_bar.progress_coming_soon', { totalNumber: formattedTotalNumber })
      }}</span>
      <span :style="{ fontSize: `${fontSize}px` }" v-show="status !== 'coming_soon'" class="h-progress-bar-text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.progress_left' : 'components.h_promotion_progress_bar.progress_left', { currentNumber: formattedCurrentNumber, totalNumber: formattedTotalNumber })
      }}</span>
      <!-- Right section -->
      <span :style="{ fontSize: `${fontSize}px`, textAlign: 'right' }" v-show="status === 'coming_soon'" class="h-progress-bar-text accent--text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.coming_soon' : 'components.h_promotion_progress_bar.coming_soon', { date: formattedStartDateWithHour })
      }}</span>
      <span :style="{ fontSize: `${fontSize}px`, textAlign: 'right' }" v-show="status === 'sold_out'" class="h-progress-bar-text accent--text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.sold_out' : 'components.h_promotion_progress_bar.sold_out', { date: formattedStartDate })
      }}</span>
      <span :style="{ fontSize: `${fontSize}px`, textAlign: 'right' }" v-show="status === 'available'" class="h-progress-bar-text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.expiration' : 'components.h_promotion_progress_bar.expiration', { date: formattedExpirationDate })
      }}</span>
      <span :style="{ fontSize: `${fontSize}px`, textAlign: 'right' }" v-show="status === 'closed'" class="h-progress-bar-text accent--text">{{
        $t($vuetify.breakpoint.smAndDown ? 'components.h_promotion_progress_bar.mobile.closed' : 'components.h_promotion_progress_bar.closed', { date: formattedExpirationDate })
      }}</span>
    </div>

    <v-progress-linear reverse class="h-progress-bar" :value="progress" height="15" :color="progressColor" />

    <div :style="{ fontSize: `${fontSize - 1}px` }" class="h-progress-bar-restriction-label">{{ $t(restrictionLabelKey, { minPurchase: formattedMinPurchase, maxPurchase: formattedMaxPurchase }) }}</div>
  </div>
</template>

<script>
export default {
  name: 'h-promotion-progress-bar',
  inheritAttrs: false,
  props: {
    expiration: {
      type: String,
    },
    start: {
      type: String,
    },
    status: {
      type: String,
    },
    currentNumber: {
      type: Number,
    },
    totalNumber: {
      type: Number,
    },
    minPurchase: {
      type: Number,
    },
    maxPurchase: {
      type: Number,
    },
    fontSize: {
      type: Number,
      default: 14,
    },
    rewardPercent: {
      type: String,
    },
    restrictionLabelKey: {
      type: String,
      default: 'components.h_promotion_progress_bar.condition',
    },
  },
  computed: {
    progressColor() {
      return this.status === 'available' ? 'var(--color-branding)' : '#737373';
    },
    progress() {
      if (this.status === 'sold_out' || this.status === 'closed') {
        return 0;
      }
      return this.totalNumber > 0 ? (this.currentNumber / this.totalNumber) * 100 : 100;
    },
    formattedExpirationDate() {
      return this.$options.filters.formatShortDate(this.expiration);
    },
    formattedStartDateWithHour() {
      return this.$options.filters.formatShortDateWithHour(this.start);
    },
    formattedStartDate() {
      return this.$options.filters.formatShortDate(this.start);
    },
    formattedCurrentNumber() {
      if (this.status === 'sold_out' || this.status === 'closed') {
        return this.$options.filters.currencySymbol(this.$options.filters.currency(0));
      }
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.currentNumber));
    },
    formattedTotalNumber() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.totalNumber));
    },
    formattedMinPurchase() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.minPurchase));
    },
    formattedMaxPurchase() {
      return this.$options.filters.currencySymbol(this.$options.filters.currency(this.maxPurchase));
    },
  },
};
</script>

<style lang="scss" scoped>
.h-promotion-progress-bar {
  border-width: 0px;
  padding: 12px;

  background-color: #f2f2f2;
  &.promotion-status--available {
    background-color: transparent;
    > .consumer-gift-card-promotion-title {
      color: var(--color-warning);
    }
  }

  > .consumer-gift-card-promotion-title {
    color: #737373;
    margin-bottom: var(--gap-xsmall);
  }
}

.h-progress-bar-text {
  font-weight: 600;
  line-height: 1.5;
}

.h-progress-bar {
  margin-top: 6px;
  margin-bottom: 6px;
}

.h-progress-bar-restriction-label {
  font-style: italic;
  line-height: 1.2;
  color: var(--color-text-secondary);
  font-weight: 400;
}
</style>
