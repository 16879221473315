<template>
  <div v-if="currentOffer.current_promotion && offerCorporateDetails.voucher_structure === 'payment'">
    <h-promotion-progress-bar
      restriction-label-key="components.h_promotion_progress_bar.condition_corpo"
      :status="currentOffer.current_promotion.status"
      :reward-percent="currentOffer.current_promotion.incentive.bonus_display"
      :expiration="currentOffer.current_promotion.availability.end_date"
      :start="currentOffer.current_promotion.availability.start_date"
      :current-number="currentOffer.current_promotion.availability.inventory.adjusted_avail_payment_amount"
      :total-number="currentOffer.current_promotion.availability.inventory.adjusted_total_payment_amount"
      :min-purchase="currentOffer.current_promotion.incentive.minimum_payment"
      :max-purchase="currentOffer.current_promotion.incentive.adjusted_user_payment_limit"
      class="promotion-progress-bar"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'corpo-promotion-progress-bar',
  computed: {
    ...mapGetters('offer', ['currentOffer', 'offerCorporateDetails']),
  },
};
</script>

<style lang="scss" scoped>
.promotion-progress-bar.h-promotion-progress-bar.promotion-progress-bar.h-promotion-progress-bar,
.promotion-progress-bar.h-promotion-progress-bar {
  max-width: 996px;
  margin: 0 auto;

  padding: var(--gap-large) var(--gap);
  border-radius: 10px;
  border: var(--border-thin) solid var(--color-border);

  @media ($md-min) {
    border-radius: 20px;
  }
}
</style>
