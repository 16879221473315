<template>
  <h-form class="corpo-order-step frame" ref="form" v-model="valid" @submit.prevent="submit()">
    <div class="frame-content">
      <h3>{{ $t('components.corpo_order_step.title') }}</h3>
      <div class="promotion-input" v-if="currentOffer.current_promotion && offerCorporateDetails.voucher_structure === 'payment'">
        <v-radio-group v-model="promotionId" style="margin-top: 0" :row="!$vuetify.breakpoint.smAndDown" hide-details>
          <v-radio class="offer-promotion-radio-option" :value="null">
            <template #label>
              <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.corpo_order_step.without') }}</p>
            </template>
          </v-radio>
          <v-radio class="offer-promotion-radio-option" :value="currentOffer.current_promotion.id">
            <template #label>
              <p class="h-label-2" style="color: var(--color-text-primary)">{{ $t('components.corpo_order_step.with', { reward_percent: currentOffer.current_promotion.incentive.bonus_display }) }}</p>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
      <div v-if="promotionId && currentOffer.current_promotion.status !== 'available'">
        <p class="h-text-2" v-if="currentOffer.current_promotion.status === 'coming_soon'" style="color: var(--color-warning)">
          {{ $t('components.corpo_order_step.use_regular_coming_soon', { date: $options.filters.formatShortDate(currentOffer.current_promotion.availability.start_date) }) }}
        </p>
        <p class="h-text-2" v-if="currentOffer.current_promotion.status === 'sold_out'">{{ $t('components.corpo_order_step.use_regular_sold_out') }}</p>
        <p class="h-text-2" v-if="currentOffer.current_promotion.status === 'closed'">{{ $t('components.corpo_order_step.use_regular_closed') }}</p>
      </div>
      <div v-else>
        <div>
          <div v-for="(order, index) in orders" :key="index" class="order-list">
            <h4 v-if="index === 0">{{ $t('components.corpo_order_step.quantity') }}</h4>
            <h4 v-if="index === 0">{{ $t('components.corpo_order_step.amount') }}</h4>
            <h4 v-if="index === 0">{{ $t('components.corpo_order_step.subtotal_label') }}</h4>
            <h4 v-if="index === 0"></h4>
            <h-text-field autofocus v-model="order.quantity" :hint="$t('components.corpo_order_step.quantity_hint')" persistent-hint type="number" :rules="quantityRules" v-maska="'###'" />

            <h-select v-if="promotionId" :ref="`amountSelector${index}`" :items="possiblePromotionAmount" v-model="order.amount" label="" item-value="value" item-text="label" :rules="amountRules" />
            <h-text-field v-else v-model="order.amount" suffix="$" :hint="$t('components.corpo_order_step.amount_hint', { min_amount: minimumPurchaseAmount })" persistent-hint type="number" :rules="amountRules" v-maska="'####'" />

            <h-text-field class="total-amount" :value="order.amount * order.quantity" suffix="$" type="number" disabled hide-details />

            <h-btn icon min-width="10" small @click="removeCurrentOrder(index)">
              <v-icon dark> mdi-close </v-icon>
            </h-btn>
          </div>
        </div>
        <h-btn @click="addOrder" text :disabled="orders.length >= 5" style="background-color: #f2f2f2; border-radius: var(--border-radius)">
          <v-icon left> mdi-plus </v-icon>
          {{ $t('components.corpo_order_step.add_quantity') }}
        </h-btn>
        <p v-if="orders.length >= 5" class="h-caption" style="color: var(--color-warning)">{{ $t('components.corpo_order_step.add_quantity_caption') }}</p>

        <div class="recap-section" style="text-align: right">
          <hr />
          <div class="recap-section-content">
            <div class="h-label-2 quantity-total">{{ $t('components.corpo_order_step.quantity') }}</div>
            <div class="h-label-2 quantity-total">{{ totalOrderAmount }}</div>

            <div class="h-heading-h4" :style="`color: ${totalOrderAmountOverMaxAmount || (promotionId && totalOrderBonusAmountOverMaxBonusAmount) ? 'var(--color-warning)' : 'var(--color-text-primary)'} `">
              {{ $t('components.corpo_order_step.recap_subtotal') }}
            </div>
            <div class="h-heading-h4" :style="`text-align: right;color: ${totalOrderAmountOverMaxAmount || (promotionId && totalOrderBonusAmountOverMaxBonusAmount) ? 'var(--color-warning)' : 'var(--color-text-primary)'} `">
              {{ totalOrderValue | currency_full | currencySymbol }}
            </div>

            <div v-if="totalOrderAmountOverMaxAmount || (promotionId && totalOrderBonusAmountOverMaxBonusAmount)"></div>

            <div v-if="totalOrderAmountOverMaxAmount" class="h-text-4" style="color: var(--color-warning)">{{ $t('components.corpo_order_step.recap_warning') }}</div>
            <div v-else-if="promotionId && totalOrderBonusAmountOverMaxBonusAmount" class="h-text-4" style="color: var(--color-warning)">
              {{ $t('components.corpo_order_step.recap_bonus_warning', { max_amount: $options.filters.currency_full(currentOffer.current_promotion.availability.inventory.adjusted_avail_payment_amount) }) }}
            </div>

            <div v-if="promotionId" class="quantity-total">{{ $t('components.corpo_order_step.recap_promo') }}</div>
            <div v-if="promotionId" class="h-label-2 quantity-total">+ {{ bonusAmount(totalOrderValue) | currency_full | currencySymbol }}</div>
          </div>
        </div>
        <h-textarea
          v-model="message"
          :label="$t('components.corpo_order_step.msg_label')"
          :hint="$t('components.corpo_order_step.msg_hint')"
          :placeholder="$t('components.corpo_order_step.msg_placeholder')"
          persistent-hint
          persistent-placeholder
          height="115"
          maxlength="150"
          :rules="messageRules"
          counter
        />
        <div class="btn-container" style="text-align: right">
          <h-btn type="submit" class="btn" :disabled="!valid || working || totalOrderAmountOverMaxAmount || noQuantityOrdered || (promotionId && totalOrderBonusAmountOverMaxBonusAmount)" :loading="working">{{
            $t('components.corpo_order_step.proceed_text')
          }}</h-btn>
        </div>
      </div>

      <corpo-order-error-modal v-model="errorModal" />
    </div>
  </h-form>
</template>

<script>
import _get from 'lodash/get';
import CorpoOrderErrorModal from '@/components/corpo/order-process/CorpoOrderErrorModal';
import safeExecute from '@/composables/safe-execute';

import { validateMaxLength, validateMaxNumLines, validateMaxValue, validateMinValue, validatePositiveInteger, validateRequiredGivenFieldLabel, validateNoRepeat } from '@/composables/validations';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex/dist/vuex.common.js';

const MAX_VALUE = 10000;

export default {
  name: 'corpo-order-step-v2',
  setup(props, { root }) {
    const { executeForm, execute, working } = safeExecute(root.$store);
    return {
      validateRequiredGivenFieldLabel,
      validateMaxNumLines,
      validateMaxLength,
      validateMinValue,
      validateMaxValue,
      validatePositiveInteger,
      executeForm,
      execute,
      working,
      validateNoRepeat,
    };
  },
  components: { CorpoOrderErrorModal },
  data() {
    return {
      orders: [
        {
          quantity: null,
          amount: null,
        },
      ],
      valid: false,
      quantity: null,
      amount: null,
      message: '',
      errorModal: false,
      quantityRules: [this.validateRequiredGivenFieldLabel(this.$t('components.corpo_order_step.quantity_label')), this.validateMinValue(1), this.validateMaxValue(200), this.validatePositiveInteger()],
      messageRules: [this.validateMaxLength(150), this.validateMaxNumLines(6)],
    };
  },
  watch: {
    async promotionId(val) {
      if (val) {
        await this.tryToRefreshCurrentOfferPromotion();
        this.$nextTick(() => {
          this.orders.forEach((order) => {
            if (!this.possiblePromotionAmount.map((e) => e.value).includes(Number(order.amount))) {
              order.amount = null;
            } else {
              order.amount = Number(order.amount);
            }
          });

          this.$refs.form.validate();
        });
      }

      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
  },
  computed: {
    ...mapGetters('offer', ['offerCorporateDetails', 'currentOffer']),
    ...mapGetters('corpo', ['currentOfferPromotionId']),
    promotionMaxAmount() {
      return Math.min(this.currentOffer.current_promotion.incentive.user_payment_limit, 500, this.currentOffer.current_promotion.availability.inventory.adjusted_avail_payment_amount);
    },
    possiblePromotionAmount() {
      const values = [];
      if (this.promotionId) {
        const max = this.promotionMaxAmount;
        for (let currentStep = 0; this.currentOffer.current_promotion.incentive.minimum_payment + currentStep <= max; currentStep = currentStep + this.currentOffer.current_promotion.incentive.step) {
          const val = this.currentOffer.current_promotion.incentive.minimum_payment + currentStep;
          values.push({
            value: Number(val),
            label: `${this.$options.filters.currencySymbol(this.$options.filters.currency(val))} (+${this.$options.filters.currencySymbol(this.$options.filters.currency(this.bonusAmount(Number(val))))})`,
          });
        }
      }
      return values;
    },
    promotionId: {
      set(val) {
        this.setOfferPromotionId(val);
      },
      get() {
        return this.currentOfferPromotionId;
      },
    },
    subtotalValue() {
      return this.quantity * this.amount;
    },
    noQuantityOrdered() {
      return this.orders.length < 1;
    },
    amountRules() {
      return [
        this.validateRequiredGivenFieldLabel(this.$t('components.corpo_order_step.amount_label')),
        this.validateMinValue(this.minimumPurchaseAmount),
        this.validateMaxValue(this.promotionId ? this.promotionMaxAmount : 500),
        this.validatePositiveInteger(),
        this.validateNoRepeat(this.orders.map((o) => o.amount)),
      ];
    },
    minimumPurchaseAmount() {
      if (this.promotionId) {
        return _get(this.currentOffer, 'current_promotion.incentive.minimum_purchase', 1);
      }
      return _get(this.offerCorporateDetails, 'minimum_purchase_amount', 1);
    },
    orderPayload() {
      return {
        quantity: this.quantity,
        amount: this.amount,
        message: this.message,
      };
    },
    exceedMaxValue() {
      return this.subtotalValue > MAX_VALUE;
    },
    totalOrderValue() {
      return this.orders.reduce((acc, order) => {
        return acc + order.amount * order.quantity;
      }, 0);
    },
    totalOrderAmount() {
      return this.orders.reduce((acc, order) => {
        return acc + parseInt(order.quantity || 0);
      }, 0);
    },
    totalOrderAmountOverMaxAmount() {
      return this.totalOrderValue > 10000;
    },
    totalOrderBonusAmountOverMaxBonusAmount() {
      return this.totalOrderValue > _get(this.currentOffer, 'current_promotion.availability.inventory.adjusted_avail_payment_amount', 0);
    },
  },
  methods: {
    ...mapActions('corpo', ['setOfferPromotionId']),
    showDetail(index) {
      if (this.$refs[`amountSelector${index}`] && this.$refs[`amountSelector${index}`][0]) {
        return this.$refs[`amountSelector${index}`][0].valid;
      }
      return false;
    },
    addOrder() {
      this.orders.push({
        quantity: null,
        amount: null,
      });
    },
    bonusAmount(amount) {
      return _get(this.currentOffer, 'current_promotion.incentive.bonus_percent', 0) * amount;
    },
    removeCurrentOrder(currentOrderIndex) {
      this.orders.splice(currentOrderIndex, 1);
    },
    submit() {
      if (this.exceedMaxValue) {
        this.errorModal = true;
      } else if (this.$refs.form.validate()) {
        this.saveOrderAndProceed();
      }
    },
    tryToRefreshCurrentOfferPromotion() {
      return this.execute({
        action: 'offer/refreshCurrentOfferPromotion',
      });
    },
    saveOrderAndProceed() {
      this.$refs.form.validate();

      if (this.valid) {
        this.execute(
          {
            action: 'corpo/setOrder',
            success: () => {
              this.$emit('next');
            },
          },
          {
            message: this.message,
            batch_quantities: this.orders,
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sections {
  @media ($md-min) {
    display: flex;
    align-items: flex-start;
  }
}

.order-section {
  @media ($sm-min) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.input {
  width: 100%;
}

.subtotal {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--gap-small) 0 var(--gap);

  @media ($md-min) {
    margin: 40px 0 0;
  }
}

.subtotal-label {
  font-size: 1.25rem;
  line-height: 1;
  font-weight: 600;
  color: var(--color-text-secondary);
}
.subtotal-value {
  font-size: 1.875rem;
  line-height: 1;
  font-weight: 600;

  &.error-text {
    color: var(--color-error);
  }
}

.input,
.subtotal-label,
.subtotal-value {
  @media ($sm-min) {
    width: calc((100% - var(--gap)) / 2);
    flex: 0 0 auto;
    text-align: right;
  }
}

::v-deep input[type='number'] {
  text-align: right;
}

// Remove spin buttons on number inputs (up/down buttons)
::v-deep input[type='number']::-webkit-outer-spin-button,
::v-deep input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield;
}

.msg-section {
  text-align: center;
  flex: 0 0 auto;
  @media ($md-min) {
    width: 354px;
    margin-left: var(--gap-xlarge);
  }
}

.btn {
  margin-top: var(--gap-large);
}

::v-deep .v-textarea .v-input__slot {
  align-items: stretch;
  height: 140px;
}

.order-list {
  @media ($md-min) {
    display: grid;
    grid-template-columns: repeat(3, 1fr) 42px;

    align-items: top;
    grid-gap: var(--gap);
  }
  margin-bottom: var(--gap);
  & > ::v-deep .v-text-field {
    padding-top: 0;
  }
}
.recap-section {
  margin-left: auto;
  max-width: 380px;
  margin-top: var(--gap-xlarge);
  margin-bottom: var(--gap-xlarge);
}
.recap-section-content {
  display: grid;
  grid-gap: 0px var(--gap-large);
  grid-template-columns: 3fr 1fr;
  padding: var(--gap-xsmall);
}
.quantity-total {
  color: var(--color-text-tertiary);
}

.offer-promotion-radio-option {
  border-radius: 5px;
  border: 2px solid var(--color-border-light);
  padding: 12px;
  min-width: 190px;
}
.promotion-input {
  margin: var(--gap) 0;
}
.promotion-progress-bar {
  margin: var(--gap) 0;
}

.total-amount {
  &.v-input--is-disabled {
    ::v-deep .v-input__control > .v-input__slot > .v-text-field__slot > * {
      color: var(--color-text-primary);
    }
  }
  ::v-deep .v-input__control > .v-input__slot {
    background-color: #f2f2f2;
  }
}
</style>
